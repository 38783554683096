import { Link, LinkProps } from '@remix-run/react';

type LinkTextProps = LinkProps & {
  variant?: 'primary' | 'accent' | 'grayscale';
  extraClasses?: string;
  obfuscated?: boolean;
  children?: React.ReactNode;
  reloadDocument?: boolean;
};

export const LinkText = ({
  variant = 'primary',
  obfuscated = false,
  extraClasses = '',
  children,
  reloadDocument = false,
  ...props
}: LinkTextProps) => {
  const variantClassesMap = {
    primary: '-primary',
    accent: '-accent',
    grayscale: '-grayscale',
  };

  const linkTextClasses = `fo-link-text ${variantClassesMap[variant]} ${extraClasses}`.trim();

  return obfuscated ? (
    <button
      className={linkTextClasses}
      onClick={() => (location.href = props['to'].toString())}
      type={'button'}
    >
      {children}
    </button>
  ) : (
    <Link
      className={linkTextClasses}
      {...props}
      reloadDocument={reloadDocument}
    >
      {children}
    </Link>
  );
};
